
import React, { useEffect, useMemo, useState, Suspense, lazy } from 'react';
import { Accordion, Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import Pagination from "react-js-pagination";

import {
    GetAllAcademicsTypes, GetAllBranches, GetAllSemisters, GetAllTenants, GetAllUsersData, commonGet,
    deleteAccesstokenData, generateResume, reactivateAccountData, deleteUserCourse, deleteUserCourseItem, deleteUserTransactions, commonDelete, deleteDeviceToken, deleteUserQuiz, deleteUser
} from '../../../services/CommonService';
import EventBus from '../../../services/EventBus';

import UserSearch from './UserSearch';
import { CSVLink, CSVDownload } from "react-csv";
// import ViewCertification from './ViewCertification';
import moment from "moment";

import { Deleteuserfromadmin } from '../../../services/CommonService';
import axios from 'axios';
// import ViewProfile from './ViewProfile';
let apiurl = "https://asseshub.com"; 
// let apiurl = "http://localhost:8080";




const AllUsers = () => {
    const notifyTopFullWidth = (message) => {
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const [postModal, setPostModal] = useState(false);
    const [modaltype, setModalType] = useState(0);

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );
    const [data, setData] = useState([]);
    const [tenantid, setTenantId] = useState(null);
    const [tenantlist, setTenantList] = useState([]);
    const [tenantdata, setTenantData] = useState({});
    const [updateitem, setUpdateItem] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [username, setUserName] = useState(null);
    const [email, setEmail] = useState(null);
    const [mobilenumber, setMobilenumber] = useState(null);
    const [searchfields, setSearchfields] = useState(null);
    const [candidatelist, setCandidateList] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(true);
    const [collegetenant, setCollegetenant] = useState([])
    const [subappid, setSubappid] = useState([])
    const [tenantselect, setTenantSelect] = useState(true)
    const [selectedDate, setSelectedDate] = useState(null);
    const [toDate,setToDate]=useState(null)

    useEffect(() => {
        //getBanner();
        getData()
        getDatalist()
        // getTenants();
        EventBus.on("loadpagedata", (data) => {
            let obj = {
                payload: {
                    "pageNo": data?.message?.page,
                    "size": 10
                }
            }
            loadData(data?.message?.page);
            //dispatch(fetchAllPaitentsForMedicalBoard({obj:obj}));
        });
        EventBus.remove("loadpagedata");

        // loaddata();
    }, []);

    const handlePageChange = async (pn) => {
        if (pn !== activePage && !isNaN(pn)) {
            loadNextsetofRecords(pn);
        }
    };
    const loadNextsetofRecords = async (pn) => {
        setActivePage(pn);
        EventBus.dispatch("loadpagedata", {
            message: {
                "page": pn
            }

        });
    };

    const searchData = async (pn, searchfields) => {
        setSearchfields(searchfields);
        let reqparams = ''
        const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));

        if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {
            reqparams = {
                pageno: pn,
                query: { tenantid: collegetenant, subapplicationid: subappid }
            }


        } else {
            reqparams = {
                pageno: pn,
                query: { tenantid: tenantid }
            }

        }
        if (searchfields != null) {
            reqparams.searchfields = searchfields;
        }

        if (username != null) {
            reqparams.query.username = { '$regex': username, '$options': 'i' };
        }
        console.log("reqparams", reqparams);

        const respData = await GetAllUsersData(reqparams);
        console.log("respData", respData.data.docs);
        setData(respData.data.docs);
        setTotalRecords(respData.data.totalDocs);
    }

    const loadData = async (pn) => {

        const reqparams = {
            pageno: pn,
            // query: { tenantid: localStorage.getItem('tenantidsearch') }
        }

        if (searchfields != null) {
            reqparams.searchfields = searchfields;
        }

        if (username != null) {
            reqparams.query.username = { '$regex': username, '$options': 'i' };
        }

        if(selectedDate!= null && toDate!=null) {
            reqparams.query.createddate={  $gte: selectedDate,
                $lt: toDate}
        }

        const respData = await GetAllUsersData(reqparams);
        setData(respData?.data.docs);
        setTotalRecords(respData?.data.totalDocs);
    }

    const getDatalist = async () => {
        // let reqparams = null;

        // if (tenantid == null) {
        //     reqparams = {
        //         pageno: '1'
        //     }
        // } else {
           const reqparams = {
                pageno: '1',
                query:{}
                // query: { tenantid: tenantid }

            }
        // }
        const respData = await GetAllUsersData(reqparams);
        // setTenantId(tenantid);
        setData(respData?.data?.docs);
        setTotalRecords(respData?.data?.totalDocs);

    }

    // const getTenants = async () => {
    //     const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
    //     if (constuserdetails.roles[0] == 'ROLE_ADMIN') {


    //         const Response = await GetAllTenants({ pageno: -1, status: 'Active' });
    //         setIsAdmin(true);
    //         setTenantList(Response.data);
    //     } else {
    //         const Response = await GetAllTenants({ pageno: -1, status: 'Active', query: { tenantid: constuserdetails.user.tenantid } })
    //         setTenantList(Response.data);
    //         setIsAdmin(false);
    //     }
    // }

    const getData = async () => {
        const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
        if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {

            const reqparams = {
                pageno: 1,
                query: { tenantid: constuserdetails.user.tenantid, subapplicationid: constuserdetails.user.subapplicationid }
            }

            const respData = await GetAllUsersData(reqparams);
            setCollegetenant(constuserdetails.user.tenantid)
            setSubappid(constuserdetails.user.subapplicationid)
            setData(respData.data.docs);
            setTotalRecords(respData.data.totalDocs);
            candidatedatalist2(constuserdetails.user.tenantid, constuserdetails.user.subapplicationid)
            setTenantSelect(false)


        }


    }

    const openModal = async (type) => {

        if (tenantid == null) {
            notifyTopFullWidth("Please choose the tenant");
        } else {
            setModalType(type);
            setUpdateItem({});
            setPostModal(true);
        }
    }


    const handleNameChange = (e) => {
        const { name, value } = e.target;


        if (value.length >= 10) {
            setUserName(value);
            const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
            if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {
                getDatalistbyname2(value);
            } else {
                getDatalistbyname(value);
            }
            candidatedatalist(tenantid, null);
        } if (value.length == 0) {
            setUserName(null);
            const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
            if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {
                getData()
            } else {
                getDatalist();

            }
            candidatedatalist(tenantid, null);
        }
        else {
            setUserName(value);
        }

        // const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
        // if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {

        //     if (value.length >= 10) {
        //         setUserName(value);

        //         candidatedatalist(collegetenant, null);
        //     } if (value.length == 0) {
        //         setUserName(null);
        //         console.log(tenantid);
        //         getData();
        //         candidatedatalist(collegetenant, null);
        //     }
        //     else {
        //         setUserName(value);
        //     }


        // }


    }

    const getDatalistbyname = async (name) => {

           const reqparams = {
                pageno: '1',
                query: { }

            }
            if (username != null) {
                reqparams.query.username = { '$regex': name, '$options': 'i' };
            }


        const respData = await GetAllUsersData(reqparams);
        setData(respData?.data.docs);
        setTotalRecords(respData?.data.totalDocs);

    }

    const getDatalistbyname2 = async (name) => {
        let reqparams = null;
        console.log(username);


        reqparams = {
            pageno: '1',
            query: { tenantid: collegetenant, subapplicationid: subappid }

        }
        if (username != null) {
            reqparams.query.username = { '$regex': name, '$options': 'i' };
        }


        const respData = await GetAllUsersData(reqparams);
        setData(respData.data.docs);
        setTotalRecords(respData.data.totalDocs);

    }


    const handleChange = (e) => {
        const { name, value } = e.target;

        const tenantdata = tenantlist.filter(t => t.tenantid == value);

        setTenantData(tenantdata[0]);
        setTenantId(value);
        getDatalist(value);

        localStorage.setItem('tenantidsearch', value);


        candidatedatalist(value, null);


    }
    const closemodal = async () => {

        getDatalist(tenantid);
        setPostModal(false);
    }

    const viewprofile = async (item) => {
        setModalType(3);
        setUpdateItem(item);
        setPostModal(true);
    }



    const changepurchaseprogram = async (item) => {
        setModalType(1);
        setUpdateItem(item);
        setPostModal(true);

    }

    const subscribetoplatform = async (item) => {
        setModalType(2);
        setUpdateItem(item);
        setPostModal(true);


    }
    const deleteaccesstoken = async (item) => {

        const reqdata = {
            userid: item.id
        }
        const resData = await deleteAccesstokenData(reqdata);
        if (resData.message == 'SUCCESS') {
            notifyTopFullWidth("Token deleted successfully");
        }

    }

    const reactivateaccount = async (item) => {
        const reqdata = {
            userid: item.id
        }
        const resData = await reactivateAccountData(reqdata);
        if (resData.message == 'SUCCESS') {
            notifyTopFullWidth("Account reactivated successfully");
        }

    }

    const sendresume = async (item) => {

        const reqdata = {
            userid: item.id
        }

        const resData = await generateResume(reqdata);
        notifyTopFullWidth("resume mailed successfully");
    }

    const [percent, setPercent] = useState(50);

    const fields = {
        branch: '',
        branchname: '',
        frommarks: '',
        tomarks: '',
        skills: '',
        certification: '',
        semister: '',
        semistername: '',
        academictype: '',
        yearname: '',
        criteria: ''

    }
    const [formfields, setFromFields] = useState(fields);
    const [semsisterdata, setSemisterData] = useState([]);
    const [branchdata, setBranchData] = useState([]);
    const [academictypes, setAcademictypes] = useState([]);
    const [stskills, setSkills] = useState([]);
    const { branch, semister, academictype, frommarks, tomarks, skills, certification, criteria } = formfields;


    const loaddata = async () => {
        const reqparams1 = {
            pageno: '-1',
            query: { status: 'Active' },
            ptype: 'SKILLS'

        }
        //settypesList(dataResponse.data);
        const SemisterResponse = await GetAllSemisters(reqparams1);
        const BranchResponse = await GetAllBranches(reqparams1);
        const AcademicResponse = await GetAllAcademicsTypes(reqparams1);
        const skillsResponse = await commonGet(reqparams1);
        setSemisterData(SemisterResponse.data);
        setBranchData(BranchResponse.data);
        setAcademictypes(AcademicResponse.data);
        setSkills(skillsResponse.data);

    }


    const validateForm = async () => {
        let fields = formfields;
        let errors = {};
        let formIsValid = true;
        // setErrorFields(errors)
        return formIsValid;

    }

    const resetform = async () => {
        setFromFields(fields);
    }

    const submitForm = async () => {
        //  console.log(formfields);
        const isValid = await validateForm();

        if (formfields.criteria != '' && formfields.branch == '' && formfields.semister == '' && formfields.academictype == ''
            && formfields.frommarks == '' && formfields.tomarks == '') {
            notifyTopFullWidth("All fields are mandatory");
        } else {
            searchData(1, formfields);
            candidatedatalist(tenantid, formfields);
        }

    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;

        setFromFields((prevState) => ({
            ...prevState,
            [name]: value
        }));


    }

    const candidatedatalist2 = async (tenantid, subapplicationid, formfields) => {

        const reqparams = {
            pageno: -1,
            query: { tenantid: tenantid, subapplicationid: subapplicationid }
        }

        if (searchfields != null) {
            reqparams.searchfields = formfields;
        }

        if (username != null) {
            reqparams.query.username = { '$regex': username, '$options': 'i' };
        }

        const candidateResponse = await GetAllUsersData(reqparams);

        const exeldata = [];

        let counter = 0;
        await candidateResponse.data.reduce(async (promise, res) => {
            await promise;

            const semisters = semsisterdata.filter(t => t.id == res.id);
            const branches = branchdata.filter(t => t.id == res.id);
            const academicstypes = academictypes.filter(t => t.id == res.id);

            const reqparam = {
                pageno: -1,
                ptype: 'USERSUBSCRIPTION',
                query: { userid: res.id, }

            }

            const  reqparams1 = {
                pageno: 1,
                query: { userid: res.id, },
                ptype: 'PROGRAMPURCHASES'
            }

            const userSubscription = await commonGet(reqparam)
            const userPurchases=await commonGet(reqparams1)


            let subscription = ''

            if (userSubscription?.data?.length > 0) {
                subscription = "Yes"
            }
            else {
                subscription = "No"

            }

            const programspurchased=userPurchases?.data?.length



            const singledata = {
                candidatename: res.name,
                candidatemail: res.email,
                candidatemobile: res.mobileno,
                avgmarks: res.avgmarks,
                iscertified: res.iscertified,
                semister: semisters[0]?.name,
                branch: branches[0]?.name,
                btechyear: academicstypes[0]?.name,
                skills: skills.toString(),
                subscribed: subscription,
                purchased:programspurchased,

            }

            let usercertficates = '';
            let checkcounter = 0;


            await res.certificates?.reduce(async (promise, res4) => {
                await promise;

                if (usercertficates == '') {
                    usercertficates = res4.name
                } else {
                    usercertficates = usercertficates + "," + res4.name;

                }
                if (checkcounter == res.certificates.length - 1) {
                    singledata.certificates = usercertficates;
                }
                checkcounter++;

            }, Promise.resolve());


            exeldata.push(singledata);

            if (counter == candidateResponse.data.length - 1) {
                console.log(exeldata);
                setCsvData(exeldata);
                // return exeldata;
            }

            counter++;

        }, Promise.resolve());



    }

    const candidatedatalist = async (tenantid, formfields) => {

        let reqparams = ''
        const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));

        if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {
            reqparams = {
                pageno: -1,
                query: { tenantid: collegetenant, subapplicationid: subappid }
            }


        } else {
            reqparams = {
                pageno: -1,
                query: { tenantid: tenantid }
            }

        }

        // const reqparams = {
        //     pageno: -1,
        //     query: { tenantid: tenantid }
        // }

        if (formfields != null) {
            reqparams.searchfields = formfields;
        }

        console.log("formfields", formfields);

        if (username != null) {
            reqparams.query.username = { '$regex': username, '$options': 'i' };
        }

        if(selectedDate!= null && toDate!=null) {
            reqparams.query.createddate={  $gte: selectedDate,
                $lt: toDate}
        }

        const candidateResponse = await GetAllUsersData(reqparams);



        const exeldata = [];

        let counter = 0;
        await candidateResponse.data.reduce(async (promise, res) => {
            await promise;
            // console.log("res", res);

            const semisters = semsisterdata.filter(t => t.id == res.id);
            const branches = branchdata.filter(t => t.id == res.id);
            const academicstypes = academictypes.filter(t => t.id == res.id);

            const reqparam = {
                pageno: -1,
                ptype: 'USERSUBSCRIPTION',
                query: { userid: res.id, }

            }

            const  reqparams1 = {
                pageno: 1,
                query: { userid: res.id, },
                ptype: 'PROGRAMPURCHASES'
            }

            const userSubscription = await commonGet(reqparam)
            const userPurchases=await commonGet(reqparams1)


            let subscription = ''

            if (userSubscription?.data?.length > 0) {
                subscription = "Yes"
            }
            else {
                subscription = "No"

            }

            const programspurchased=userPurchases?.data?.length


            const singledata = {
                candidatename: res.name,
                candidatemail: res.email,
                createddate: moment(res.createddate).format('YYYY-MM-DD h:mm:ss a'),
                candidatemobile: res.mobileno,
                avgmarks: res.avgmarks,
                iscertified: res.iscertified,
                semister: semisters[0]?.name,
                branch: branches[0]?.name,
                btechyear: academicstypes[0]?.name,
                skills: skills.toString(),
                fbaddid: res.fbaddid,
                subscribed: subscription,
                purchased:programspurchased,

            }

            let usercertficates = '';
            let checkcounter = 0;


            await res.certificates?.reduce(async (promise, res4) => {
                await promise;

                if (usercertficates == '') {
                    usercertficates = res4.name
                } else {
                    usercertficates = usercertficates + "," + res4.name;

                }
                if (checkcounter == res.certificates.length - 1) {
                    singledata.certificates = usercertficates;
                }
                checkcounter++;

            }, Promise.resolve());


            exeldata.push(singledata);

            if (counter == candidateResponse.data.length - 1) {
                // console.log(exeldata);
                setCsvData(exeldata);
                // return exeldata;
            }

            counter++;

        }, Promise.resolve());



    }

    const headers = [
        { label: "Candidate Name", key: "candidatename" },
        { label: "Email", key: "candidatemail" },
        { label: "createddate", key: "createddate" },
        { label: "Mobile number", key: "candidatemobile" },
        { label: "FB Addid", key: "fbaddid" },
        { label: "Subscription", key: "subscribed" },
        { label: "Programs Enrolled", key: "purchased" },
        { label: "Branch", key: "branch" },
        { label: "Year", key: "btechyear" },
        { label: "Semister", key: "semister" },
        { label: "Semister Marks", key: "semistermarks" },
        { label: "Avg Marks", key: "avgmarks" },
        { label: "Skills", key: "skills" },
        { label: "Is Certified", key: "iscertified" },
        { label: "Certificates", key: "certificates" }

    ];


    // const deleteThisUser = async (item) => {
    //     console.log(item, " check user item")
    //     try {
    //         const reqdata = {
    //             userid: item.id
    //         }
    //         const reqparamskills = {
    //             pageno: '-1',
    //             ptype: 'SKILLS'
    //         }
    //         const reqparamarks = {
    //             pageno: '-1',
    //             ptype: 'USERMARKS'
    //         }
    //         const reqparamprojects = {
    //             pageno: '-1',
    //             ptype: 'USERPROJECTS'
    //         }
    //         const reqparamhobbies = {
    //             pageno: '-1',
    //             ptype: 'USERHOBBIES'
    //         }
    //         const reqparamrefferences = {
    //             pageno: '-1',
    //             ptype: 'USEREFERENCES'
    //         }
    //         const reqparamsubscrition = {
    //             pageno: '-1',
    //             ptype: 'USERSUBSCRIPTION'
    //         }
    //         const reqparamcertificate = {
    //             pageno: "-1",
    //             ptype: 'USERCERTIFICATE',
    //             userid: item.id
    //         }
    //         const reqparamdevicetoken = {
    //             mobileno: item.mobileno
    //         }
    //         const reqparameducation = {
    //             pageno: '-1',
    //             ptype: 'USEREDUCATION'
    //         }

    //         const deleteCourse = await deleteUserCourse(reqdata)
    //         const delteCourseItem = await deleteUserCourseItem(reqdata)
    //         const deleteTransactions = await deleteUserTransactions(reqdata)
    //         const deleteSKills = await commonDelete(reqparamskills)
    //         const deleteMarks = await commonDelete(reqparamarks)
    //         const deleteHobbies = await commonDelete(reqparamhobbies)
    //         const deleteReferences = await commonDelete(reqparamrefferences)
    //         const deleteSubscrition = await commonDelete(reqparamsubscrition)
    //         const deleteCertificates = await commonDelete(reqparamcertificate)
    //         const deleteProjects = await commonDelete(reqparamprojects)
    //         const deleteEducation = await commonDelete(reqparameducation)
    //         const deleteQuiz = await deleteUserQuiz(reqdata)
    //         const deleteaccesstoken = await deleteAccesstokenData(reqdata)
    //         const deleteTheUser = await deleteUser(reqdata)
    //         // const deleteuserDeviceToken = await deleteDeviceToken(reqparamdevicetoken)

    //         if (deleteTheUser.status == 200) {
    //             notifyTopFullWidth("User deleted Successfully!");
    //             loaddata();
    //             getTenants();
    //         }

    //         // console.log(deleteTheUser.status==200,"deleted")
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     // const resData = await deleteAccesstokenData(reqdata);
    //     // if(resData.message =='SUCCESS'){
    //     //     const reqParams={
    //     //         pageno:'-1',
    //     //         query:{userid:item.id}
    //     //     }
    //     //     console.log(item, "check item data")
    //     //   const response = await Deleteuserfromadmin(reqParams)

    //     // notifyTopFullWidth("User deleted successfully");
    //     // }





    //     loadData()
    // }

    const reqparamskills = {
        pageno: '-1',
        ptype: 'SKILLS'

    }
    const reqparamarks = {
        pageno: '-1',
        ptype: 'USERMARKS'
    }
    const reqparamprojects = {
        pageno: '-1',
        ptype: 'USERPROJECTS'
    }
    const reqparamhobbies = {
        pageno: '-1',
        ptype: 'USERHOBBIES'
    }
    const reqparamrefferences = {
        pageno: '-1',
        ptype: 'USEREFERENCES'
    }
    const reqparamsubscrition = {
        pageno: '-1',
        ptype: 'USERSUBSCRIPTION'
    }
    //   const reqparamcertificate={
    //     pageno:"-1",
    //     ptype:'USERCERTIFICATE',
    //     userid: item.id
    //   }
    //   const reqparamdevicetoken={
    //     mobileno:item.mobileno
    //   }
    const reqparameducation = {
        pageno: '-1',
        ptype: 'USEREDUCATION'
    }


   


    const handleDateChange = async(e) => {
        const { name, value } = e.target;
        if(name=="selectedDate"){
            setSelectedDate(value);

        }
        if(name=="toDate"){
            setToDate(value)
        }

       
        
    };

    const SearchByDate=async()=>{

        const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
        if (constuserdetails.roles[0] == 'ROLE_COLLEGE_ADMIN') {
            getDatalistbydate2();
        } else {
            getDatalistbydate()
        }
        candidatedatalist(tenantid, null);
    }

    const getDatalistbydate = async () => {

        let reqparams = null;
        if (tenantid == null) {
            notifyTopFullWidth("Please choose tenant id");
        } else {
            reqparams = {
                pageno: '1',
                query: { tenantid: tenantid,
                    createddate : {  $gte: selectedDate,$lt: toDate} }

            }

            const respData = await GetAllUsersData(reqparams);
            setData(respData?.data?.docs);
            setTotalRecords(respData?.data?.totalDocs);

        }

      

    }

    const getDatalistbydate2 = async () => {

        let reqparams = null;
            reqparams = {
                pageno: '1',
                query: { tenantid: collegetenant, subapplicationid: subappid,
                    createddate : {  $gte: selectedDate,$lt: toDate} }

            }

        const respData = await GetAllUsersData(reqparams);
        setData(respData?.data?.docs);
        setTotalRecords(respData?.data?.totalDocs);

    }



    return (<>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <Col lg={12}>
            <Card>
                <Card.Header>

                    <Card.Title>Users
                        &nbsp; <u> <CSVLink
                            filename="all_Candidates_list"
                            data={csvdata}
                            headers={headers}>
                            Download Candidates
                        </CSVLink> </u>



                    </Card.Title>
                    {/* {isAdmin && <h4>
                        <label htmlFor="fileInput" className="upload-label">
                            <u>  Upload File</u>

                        </label>
                        <input
                            type="file"
                            id="fileInput"
                            onChange={uploadFile}
                            style={{ display: 'none' }}
                        />
                    </h4>} */}



                    {/* {tenantselect && <div className="col-lg-3">
                        <select className="form-control" name="tenant"
                            value={tenantid} onChange={handleChange}>
                            <option value=''>Select Tenant</option>
                            {tenantlist.map(item => (
                                <option
                                    key={item.tenantid}
                                    value={item.tenantid}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>} */}
                    <div className="col-lg-2">
                        <input component="input"
                            placeholder="Search by Mobilenumber"
                            name="username"
                            className="form-control"

                            value={username} maxLength={50} onChange={handleNameChange} />
                    </div>




                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>

                                <th>
                                    <strong>User Name</strong>
                                </th>
                                <th>
                                    <strong>Email</strong>
                                </th>
                                <th>
                                    <strong>Mobile Number</strong>
                                </th>
                                <th>
                                    <strong>Created Date</strong>
                                </th>
                                <th>
                                    <strong>FB addid</strong>
                                </th>
                                <th>
                                    <strong>Is  Certified</strong>
                                </th>

                                <th>
                                    <strong>Skills</strong>
                                </th>




                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(item => (
                                <tr>
                                    <td>
                                        <strong>{item.name}</strong>
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.username}</td>
                                    <td>
                                        <strong>{moment(item.createddate).format('YYYY-MM-DD h:mm:ss a')}</strong>
                                    </td>
                                    <td>{item.fbaddid}</td>
                                    <td>{item.iscertified}</td>
                                    <td>{item?.skillnames?.toString()}</td>

                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                className="light sharp i-false"
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {isAdmin && <>  <Dropdown.Item onClick={() => changepurchaseprogram(item)}>
                                                    Change Purchase Program

                                                </Dropdown.Item ></>}

                                                <Dropdown.Item onClick={() => deleteaccesstoken(item)}>
                                                    Delete Access Token

                                                </Dropdown.Item >

                                                <Dropdown.Item onClick={() => reactivateaccount(item)}>
                                                    Reactivate Account

                                                </Dropdown.Item >

                                                {isAdmin && <>< Dropdown.Item onClick={() => subscribetoplatform(item)}>
                                                    Subscriptions

                                                </Dropdown.Item ></>}

                                                {/* <Dropdown.Item onClick={() => viewprofile(item)}>
                                                    View Profile

                                                </Dropdown.Item > */}


                                                <Dropdown.Item onClick={() => sendresume(item)}>
                                                    Send Resume

                                                </Dropdown.Item >

                                                {/* <Dropdown.Item onClick={() => deleteThisUser(item)}>
                                                    Delete User
                                                </Dropdown.Item > */}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>

                    <div >

                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            activeClass="active"
                            itemClass="page-item"
                            innerClass="pagination mb-0"
                            linkClass="page-link"
                            firstPageText="First"
                            lastPageText="Last"
                            prevPageText="Previous"
                            nextPageText="Next"
                            disabledClass="disabled"
                            activeLinkClass="disabled"
                        />
                    </div>
                </Card.Body>
            </Card>
        </Col >

        <Modal className="modal fade" size="xl" show={postModal} onHide={setPostModal} >
            <div className="" role="document">
                <div className="">
                    <form >
                        <div className="modal-header">

                            <button type="button" className="btn-close" onClick={closemodal} data-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">

                            <Suspense fallback={<div>Loading</div>}>
                                {/* {modaltype == 1 && <>
                                    <ChangeProgram userprops={updateitem} />
                                </>} */}
                                {/* {modaltype == 2 && <>
                                    <UserSubscription userprops={updateitem} />
                                </>} */}

                                {/* {modaltype == 3 && <>
                                    <ViewProfile userprops={updateitem}/>
                                </>} */}

                                {modaltype == 4 && <>
                                    <UserSearch />
                                </>}



                            </Suspense>
                        </div>
                        {/*<div className="modal-footer">
                              <button type="submit" className="btn btn-primary" >Add</button>  
                              <button type="button" onClick={()=> setPostModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>      
</div>*/}
                    </form>

                </div>
            </div>
        </Modal>

    </>)
}
export default AllUsers;