import axios from 'axios';
//let apiurl = "http://13.232.58.18:8080";
// let apiurl ="https://asseshub.com";
// let apiurl ="http://localhost:8080";
let apiurl = "https://jcyuva.com:444";

export const Signup = async (fields) => {
    return await axios.post(apiurl + "/api/v4/auth/signup", fields)
        .then((res) => {
            return res.data;
        }).catch((error) => {
            // window.location = "/";
            return error;
        });


}



export const LoginForm = async (fields) => {
    return await axios.post(apiurl + "/api/auth/login", fields)
        .then((res) => {
            return res.data;
        }).catch((error) => {
            // window.location = "/";
            return error;
        });


}

export const forgotPasswordAdmin = async (fields) => {
    return await axios.post(apiurl + "/api/v4/auth/forgotpasswordadmin", fields)
        .then((res) => {
            return res.data;
        }).catch((error) => {
            // window.location = "/";
            return error;
        });


}


export const ChangeTenantPassword= async (data)=>{
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
     
    }
    return await axios.post(apiurl + "/api/auth/changetenantpassword",data,{'headers':headers})
    .then((res) => {
        console.log("getting data",res.data);
        return res.data;
    }).catch((error) => {
        localStorage.removeItem('userDetails');
        // window.location.reload();
        return error;
    });

}


export const GetResumeFormats = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/resumeformat/get",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const saveResumeFormat= async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/resumeformat/save",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const updateResumeFormat= async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/resumeformat/update",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const deleteResumeFormat= async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/resumeformat/delete",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const GetAllAcademicsTypes = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/academicstypes/get",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


} 

export const GetAllBranches = async (data) => {
    
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));

    
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/branches/get",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


} 
export const GetAllSemisters = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/semisters/get",data,{'headers':headers})
        .then((res) => {
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


} 
export const GetAllUsersData= async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
       
    }
    return await axios.post(apiurl + "/api/v4/users/get",data,{'headers':headers})
        .then((res) => {
          
            return res.data;
            
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            {console.log("settings data",error)}
            return error;
        });


} 
export const commonGet = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/common/get",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const commonSave = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/common/save",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const commonUpdate = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/common/update",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const deleteAccesstokenData = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/users/deleteaccesstoken",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const generateResume = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/users/resume",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}
export const reactivateAccountData = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/users/reactivate",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}

export const commonDelete = async (data) => {
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
        
       

    }
    return await axios.post(apiurl + "/api/v4/common/delete",data,{'headers':headers})
        .then((res) => {
           
            return res.data;
        }).catch((error) => {
            localStorage.removeItem('userDetails');
            // window.location.reload();
            return error;
        });


}
export const deleteUser = async(data)=>{
    const constuserdetails = JSON.parse(localStorage.getItem('userDetails'));
	
    const headers = {
        'content-type': 'application/json',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'SAMEORIGIN',
        'x-access-token': constuserdetails.accessToken,
    }
    return await axios.post(apiurl + "/api/v4/users/deleteuser",data,{'headers':headers})
    .then((res) => {
       
        return res.data;
    }).catch((error) => {
        localStorage.removeItem('userDetails');
        // window.location.reload();
        return error;
    });
}
