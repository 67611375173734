
import React, { useEffect, useState } from 'react';
import { commonSave,commonUpdate} from '../../../../services/CommonService';
import { ToastContainer, toast } from "react-toastify";




const AddDeclaration = ({updateprop }) => {

   
    const notifyTopFullWidth = (message) => {
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const fields = {
        name: '',
        status: '',
        ptype:'DECLARATION'
        
     
    }

    const errors = {
       status: '',
        name:''
  

    }

    const [errorFields, setErrorFields] = useState(errors);
    const [formfields, setFromFields] = useState(fields);
   

    useEffect(() => {
        setFieldData();


    }, []);



    const { status,name } = formfields;

    const setFieldData = async () => {
         if(updateprop?.id){
         setFromFields(updateprop);
          
         }
    }

  
    const validateForm = async () => {
        let fields = formfields;
        let errors = {};
        let formIsValid = true;

      

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "*Please enter declaration .";
        }
       
        if (!fields["status"]) {
            formIsValid = false;
            errors["status"] = "*Please select status .";
        }
      
        setErrorFields(errors)
        return formIsValid;

    }

    const submitForm = async () => {
        console.log(formfields);
        const isValid = await validateForm();
        if (isValid) {
            if (!formfields.id) {
                //save
                const Response = await commonSave(formfields);
                if(Response.data.id){
                    notifyTopFullWidth("Data saved successfully");
                    formfields.id =Response.data.id;
                }

            } else {
                //update
                formfields.ptype='DECLARATION';
                const Response = await commonUpdate(formfields);
                if(Response.data.id){
                    notifyTopFullWidth("Data updated successfully");
                    formfields.id =Response.data.id;
                }
            }

        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFromFields((prevState) => ({
            ...prevState,
            [name]: value
        }));

    }


    return (<>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Declaration
            </label>
            <div className="col-lg-6">
            <textarea
                    rows={8}
                    className="form-control"
                    name="name"
                    placeholder="Declaration"
                    id="name"
                    onChange={handleChange}
                    value ={name}
                />


            </div>
            <div className="errorMsg">{errorFields.name}</div>
        </div>

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Status
            </label>
            <div className="col-lg-6">
                <select
                    className="form-control" name='status'
                    onChange={handleChange} value={status}>
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                </select>

            </div>
            <div className="errorMsg">{errorFields.status}</div>
        </div>


    
        <br />
        <div className="form-group">
            <button onClick={submitForm} className="btn btn-primary" type="button"
            >Save</button>
        </div>

    </>)

}

export default AddDeclaration