import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { saveResumeFormat, updateResumeFormat } from '../../../../services/CommonService';
import FileBase64 from 'react-file-base64';

function AddResumeFormat({ updateprop }) {

    const notifyTopFullWidth = (message) => {
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const fields = {
        position: '',
        status: '',
        base64: '',
        title: '',
        resumetype:''


    }

    const errors = {
        position: '',
        status: '',
        base64: '',
        title: '',
        resumetype:''


    }

    const [errorFields, setErrorFields] = useState(errors);
    const [formfields, setFromFields] = useState(fields);
    const [filefields, setFileFields] = useState(null);
    const [imageShow, setImageshow] = useState(false);


    useEffect(() => {
        setFieldData();


    }, []);



    const { status, title, position, base64, resumetype } = formfields;

    const setFieldData = async () => {
        if (updateprop?.id) {
            setFromFields(updateprop);
            setFileFields(updateprop.image);
            setImageshow(true);

        }
    }

    const validateForm = async () => {
        let fields = formfields;
        let errors = {};
        let formIsValid = true;



        if (!fields["position"]) {
            formIsValid = false;
            errors["position"] = "*Please select display position .";
        }

        if (!fields["title"]) {
            formIsValid = false;
            errors["title"] = "*Please enter title .";
        }
        if (!fields["resumetype"]) {
            formIsValid = false;
            errors["resumetype"] = "*Please enter resumetype .";
        }
        if (!fields["status"]) {
            formIsValid = false;
            errors["status"] = "*Please select status .";
        }
        if (filefields == null) {
            errors["base64"] = "*Please choose image .";
        }

        setErrorFields(errors)
        return formIsValid;

    }

    function handleChangeFile(files) {
        console.log(files);
        setFileFields(files[0]);
        if (files[0].type === 'image/png' || files[0].type === 'image/jpeg' || files[0].type === 'image/jpg') {
            let sz = files[0].size.split(' ');
            if (sz[0] > 200) {
                toast('File size should be below 200 kb', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setFileFields(files[0].base64);
                //setImageShow(files[0].base64);
                setImageshow(true);
            }
        } else {
            toast('Please upload only png or jpg', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        //setFile(event.target.files[0])
    }

    const submitForm = async () => {
        console.log(formfields);
        const isValid = await validateForm();
        if (isValid) {
            formfields.image = filefields;
            if (!formfields.id) {
                //save
                const Response = await saveResumeFormat(formfields);
                if (Response.data.id) {
                    notifyTopFullWidth("Data saved successfully");
                    formfields.id = Response.data.id;
                }

            } else {
                //update
                formfields.ptype = 'SKILLS';
                const Response = await updateResumeFormat(formfields);
                if (Response.data.id) {
                    notifyTopFullWidth("Data updated successfully");
                    formfields.id = Response.data.id;
                }
            }

        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFromFields((prevState) => ({
            ...prevState,
            [name]: value
        }));

    }

    return (
        <>

            <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Position
                </label>
                <div className="col-lg-6">
                    <input component="input"
                        placeholder="Display Order"
                        name="position"
                        className="form-control"

                        value={position} maxLength={50} onChange={handleChange} />

                </div>
                <div className="errorMsg">{errorFields.position}</div>
            </div>
            <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Title
                </label>
                <div className="col-lg-6">
                    <input component="input"
                        placeholder="Title"
                        name="title"
                        className="form-control"

                        value={title} maxLength={50} onChange={handleChange} />

                </div>
                <div className="errorMsg">{errorFields.title}</div>
            </div>

            <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Resume Type
                </label>
                <div className="col-lg-6">
                    <select
                        className="form-control" name='resumetype'
                        onChange={handleChange} value={resumetype}>
                        <option value="">Select Resumetype</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>

                </div>
                <div className="errorMsg">{errorFields.resumetype}</div>
            </div>

            <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Status
                </label>
                <div className="col-lg-6">
                    <select
                        className="form-control" name='status'
                        onChange={handleChange} value={status}>
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>

                </div>
                <div className="errorMsg">{errorFields.status}</div>
            </div>
            <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Banner Image (1020w * 680h)
                </label>
                <div className="custom-file">
                    <FileBase64
                        multiple={true}
                        onDone={handleChangeFile} />
                </div>
                <div className="errorMsg">{errorFields.base64}</div>
            </div>

            <br />
            {imageShow && <> <div className="form-group mb-3 row">
                <label
                    className="col-lg-4 col-form-label"
                    htmlFor="val-username"
                >
                    Uploaded Banner
                </label>
                <img src={filefields} alt="Red dot" />

            </div> </>}
            <br />
            <div className="form-group">
                <button onClick={submitForm} className="btn btn-primary" type="button"
                >Save</button>
            </div>

        </>
    )
}

export default AddResumeFormat