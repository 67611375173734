
import React, { useEffect, useState } from 'react';
import { GetAllAcademicsTypes, GetAllBranches, GetAllSemisters, commonGet, commonSave,commonUpdate} from '../../../services/CommonService';
import { ToastContainer, toast } from "react-toastify";
import EventBus from '../../../services/EventBus';




const UserSearch = ({updateprop }) => {

   
    const notifyTopFullWidth = (message) => {
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const fields = {
        branch: '',
        branchname:'',
        frommarks:'',
        tomarks:'',
        skills:'',
        certification:'',
        semister:'',
        semistername:'',
        academictype:'',
        yearname:''
     
    }

    

    //const [errorFields, setErrorFields] = useState(errors);
    const [formfields, setFromFields] = useState(fields);
    const [semsisterdata,setSemisterData] =useState([]);
    const [branchdata,setBranchData] =useState([]);
    const [academictypes,setAcademictypes] =useState([]);
    const [stskills,setSkills] =useState([]);
   

    useEffect(() => {
       setFieldData();
        loaddata();


    }, []);



    const { branch,semister,academictype,frommarks,tomarks,skills,certification } = formfields;

    const setFieldData = async () => {
         if(updateprop?.id){
         setFromFields(updateprop);
          
         }
    }

    const loaddata =async() =>{
        const reqparams1 = {
            pageno: '-1',
            query: { status: 'Active' },
            ptype:'SKILLS'

        }
        //settypesList(dataResponse.data);
        const SemisterResponse = await GetAllSemisters(reqparams1);
        const BranchResponse = await GetAllBranches(reqparams1);
        const AcademicResponse = await GetAllAcademicsTypes(reqparams1);
        const skillsResponse = await commonGet(reqparams1);
        setSemisterData(SemisterResponse.data);
        setBranchData(BranchResponse.data);
        setAcademictypes(AcademicResponse.data);
        setSkills(skillsResponse.data);
     
    }

  
    const validateForm = async () => {
        let fields = formfields;
        let errors = {};
        let formIsValid = true;
       // setErrorFields(errors)
        return formIsValid;

    }

    const resetform =async() =>{
        setFromFields(fields);
    }

    const submitForm = async () => {
      //  console.log(formfields);
        const isValid = await validateForm();

        EventBus.dispatch("usersearch",formfields);
        
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFromFields((prevState) => ({
            ...prevState,
            [name]: value
        }));

    }


    return (<>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Branch
            </label>
            <div className="col-lg-6">
            <select className="form-control" name="branch"
                            value={branch} onChange={handleChange}>
                            <option value=''>Select</option>
                            {branchdata.map(item => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
            </div>
           
        </div>


        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
               Academic Year
            </label>
            <div className="col-lg-6">
            <select className="form-control" name="academictype"
                            value={academictype} onChange={handleChange}>
                            <option value=''>Select</option>
                            {academictypes.map(item => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>

            </div>
        </div>

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Semister
            </label>
            <div className="col-lg-6">
            <select className="form-control" name="semister"
                            value={semister} onChange={handleChange}>
                            <option value=''>Select</option>
                            {semsisterdata.map(item => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>

            </div>
        </div>


        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Marks From (%)
            </label>
            <div className="col-lg-6">
                <input component="input"
                    placeholder="Marks From "
                    name="frommarks"
                    className="form-control"

                    value={frommarks} maxLength={50} onChange={handleChange} />

            </div>
        </div>


        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Marks To (%)
            </label>
            <div className="col-lg-6">
                <input component="input"
                    placeholder="To Marks"
                    name="tomarks"
                    className="form-control"

                    value={tomarks} maxLength={50} onChange={handleChange} />

            </div>
        </div>


        <br />

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                Skills
            </label>
            <div className="col-lg-6">
            <select className="form-control" name="skills"
                            value={skills} onChange={handleChange}>
                            <option value=''>Select</option>
                            {stskills.map(item => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>

            </div>
        </div>

        <div className="form-group mb-3 row">
            <label
                className="col-lg-4 col-form-label"
                htmlFor="val-username"
            >
                IS Certifications
            </label>
            <div className="col-lg-6">
                <select
                    className="form-control" name='certification'
                    onChange={handleChange} value={certification}>
                    <option value="">Select </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>

            </div>
          
        </div>


    
        <br />
        <div className="form-group">
            <button onClick={submitForm} className="btn btn-primary" type="button"
            >Search</button>
            <button onClick={resetform} className="btn btn-warning" type="button"
            >Reset</button>
        </div>
       

    </>)

}

export default UserSearch